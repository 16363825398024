import React, { Component } from "react";
import { connect } from "react-redux";

import { Route, Switch, withRouter } from "react-router-dom";

import { io } from "socket.io-client";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import SideMenu from "../components/sidemenu";

import Summary from "../panels/summary";
import Setting from "../panels/setting";
import SalesAll from "../panels/salesAll";

import BrandAll from "../panels/brandAll";
import BrandAdd from "../panels/brandAdd";
import BrandEdit from "../panels/brandEdit";

import BoothAll from "../panels/boothAll";
import BoothArchive from "../panels/boothArchive";
import BoothAdd from "../panels/boothAdd";
import BoothEdit from "../panels/boothEdit";

import FrameAll from "../panels/frameAll";
import FrameAllV2 from "../panels/frameAllv2";
import FrameAdd from "../panels/frameAdd";
import FrameAddV2 from "../panels/frameAddv2";
import FrameAddV3 from "../panels/frameAddv3";
import FrameEdit from "../panels/frameEdit";
import FrameEditV2 from "../panels/frameEditv2"

import CouponAll from "../panels/couponAll";
import CouponAdd from "../panels/couponAdd";
import CouponEdit from "../panels/couponEdit";

import PhotoAll from "../panels/photoAll";
import GifAll from "../panels/gifAll";
import FrameArchive from "../panels/frameArchive";
import FrameArchiveV2 from "../panels/frameArchiveV2";
import CategoryAll from "../panels/categoryAll";
import CategoryAdd from "../panels/categoryAdd";
import CategoryEdit from "../panels/categoryEdit";
import VideoAll from "../panels/videoAll";
import VideoAdd from "../panels/videoAdd";
import VideoEdit from "../panels/videoEdit";
import FilterAdd from "../panels/filterAdd";
import FilterAll from "../panels/filterAll";
import FilterEdit from "../panels/filterEdit";
import PackageAll from "../panels/packageAll";
import PackageAdd from "../panels/packageAdd";
import PackageEdit from "../panels/packageEdit";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      boothSocket: [],
      socket: null,
      err: null,
    };

    // this.setBooth = this.setBooth.bind(this);
  }

  // setBooth(){
  //     // console.log("setbooth");
  //     this.props.setBoothAction({data:1})
  // }

  componentDidMount() {
    var ctx = this;
    var socket = io("https://photograms-socket.dengansenanghati.xyz", {
      query: "boothkey=admin",
    });
    socket.on("connect", () => {
      console.log("Socket connect", socket.id);
      socket.emit("state-change", { boothkey: "admin", state: "dashboard" });
    });
    socket.on("state-changed", (arg) => {
      ctx.setState({ boothSocket: arg.data });
      this.props.setBoothAction({ data: arg.data });
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnect", socket.id);
    });
    this.setState({ socket: socket });

    if (this.props.userData.roles === "admin") {
      this.setState({ isAdmin: true });
    }
  }

  // componentDidUpdate(){
  //     console.log(this.props.userData);
  // }

  render() {
    const url = this.props.match.url;

    return (
      <Box
        className={
          "menu-side-" + this.props.menuOn + " main-container-dashboard"
        }
      >
        <Box className="dashboard-left-panel">
          <SideMenu />
        </Box>
        <Box className="dashboard-right-panel">
          {/* <Typography onClick={this.setBooth}>Set Booth</Typography> */}
          <Switch>
            <Route exact path={`${url}`}>
              <Summary title="Summary" />
            </Route>
            <Route exact path={`${url}/brand`}>
              <BrandAll title="All Brands" />
            </Route>
            <Route exact path={`${url}/brand/add`}>
              <BrandAdd title="Add Brand" />
            </Route>
            <Route exact path={`${url}/brand/edit/:id`}>
              <BrandEdit title="Edit Brands" />
            </Route>
            <Route exact path={`${url}/booth`}>
              <BoothAll title="All Booths" socket={this.props.booths} />
            </Route>
            <Route exact path={`${url}/booth/add`}>
              <BoothAdd title="Add Booth" />
            </Route>
            <Route exact path={`${url}/booth/archive`}>
              <BoothArchive title="Archive Booth" />
            </Route>
            <Route exact path={`${url}/booth/edit/:id`}>
              <BoothEdit title="Edit Booth" socket={this.props.booths} />
            </Route>
            <Route exact path={`${url}/frame`}>
              <FrameAll title="All Frames" />
            </Route>
            <Route exact path={`${url}/framev2`}>
              <FrameAllV2 title="All Frames V2" />
            </Route>
            <Route exact path={`${url}/frame/archive`}>
              <FrameArchive title="Archive Frames" />
            </Route>
            <Route exact path={`${url}/framev2/archive`}>
              <FrameArchive title="Archive Frames V2" />
            </Route>
            <Route exact path={`${url}/frame/add`}>
              <FrameAdd title="Add Frame" />
            </Route>
            <Route exact path={`${url}/frame/addv2`}>
              <FrameAddV2 title="Add Frame V2" />
            </Route>
            <Route exact path={`${url}/framev2/addv3`}>
              <FrameAddV3 title="Add Frame V3" />
            </Route>
            <Route exact path={`${url}/frame/edit/:slug`}>
              <FrameEdit title="Edit Frame" />
            </Route>
            <Route exact path={`${url}/framev2/edit/:slug`}>
              <FrameEditV2 title="Edit Frame" />
            </Route>
            <Route exact path={`${url}/frame/add`}>
              <Container className="main-panel summary">
                <Typography>Add Frame</Typography>
              </Container>
            </Route>

            <Route exact path={`${url}/category`}>
              <CategoryAll title="Frame Category" />
            </Route>
            <Route exact path={`${url}/category/add`}>
              <CategoryAdd title="Frame Category Add" />
            </Route>
            <Route exact path={`${url}/category/edit/:slug`}>
              <CategoryEdit title="Frame Category Edit" />
            </Route>
            {/* <Route exact path={`${url}/category/add/:id`}>
              <CategoryEdit title="Frame Category Edit" />
            </Route> */}

            <Route exact path={`${url}/sales`}>
              <SalesAll title="Sales Report" />
            </Route>
            <Route exact path={`${url}/photo`}>
              <PhotoAll title="All Photo" />
            </Route>
            <Route exact path={`${url}/gif`}>
              <GifAll title="All Gif" />
            </Route>
            {/* {this.state.isAdmin ? (
              <Route exact path={`${url}/sales`}>
                <SalesAll title="Sales Report" />
              </Route>
            ) : (
              ""
            )} */}

            <Route exact path={`${url}/coupon`}>
              <CouponAll title="All Voucher" />
            </Route>
            <Route exact path={`${url}/coupon/add`}>
              <CouponAdd title="Create Voucher" />
            </Route>
            <Route exact path={`${url}/coupon/edit/:code`}>
              <CouponEdit title="Edit Voucher" />
            </Route>
            <Route exact path={`${url}/setting`}>
              <Setting title="Setting" />
            </Route>
            <Route exact path={`${url}/video`}>
              <VideoAll title="All Video" />
            </Route>
            <Route exact path={`${url}/video/add`}>
              <VideoAdd title="Create Video" />
            </Route>
            <Route exact path={`${url}/video/edit/:slug`}>
              <VideoEdit title="Edit Video" />
            </Route>
            <Route exact path={`${url}/filter`}>
              <FilterAll title="All Filter" />
            </Route>
            <Route exact path={`${url}/filter/add`}>
              <FilterAdd title="Create Filter" />
            </Route>
            <Route exact path={`${url}/filter/edit/:slug`}>
              <FilterEdit title="Edit Filter" />
            </Route>

            {/* package */}
            <Route exact path={`${url}/package`}>
              <PackageAll title="All Package" />
            </Route>
            <Route exact path={`${url}/package/add`}>
              <PackageAdd title="Create Package" />
            </Route>
            <Route exact path={`${url}/package/edit/:slug`}>
              <PackageEdit title="Edit Package" />
            </Route>

            <Route exact path={`${url}/remote`}>
              <Container className="main-panel summary">
                <Typography>Remote</Typography>
              </Container>
            </Route>
            {/* 
                        <Route exact path={`${url}/library`}>
                            <Container className='main-panel summary'><Typography>Library</Typography></Container>
                        </Route>
                        <Route exact path={`${url}/library/add`}>
                            <Container className='main-panel summary'><Typography>Library Add</Typography></Container>
                        </Route>
                        <Route exact path={`${url}/library/edit`}>
                            <Container className='main-panel summary'><Typography>Library Edit</Typography></Container>
                        </Route> 
                        */}
          </Switch>
        </Box>
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setBoothAction: (data) => dispatch({ type: "SET_BOOTH", payload: data }),
    // setBooth: boothData => dispatch(setBooth(boothData))
    // logout: userData => dispatch(logout(userData))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
