import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { format } from "date-fns";

import {
  getFrameAll,
  deleteFrame,
  getFrameSearch,
} from "../redux/actions/frames";
import { getBrandAll } from "../redux/actions/brands";

import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Stack,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

class FrameAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_idbrand: 0,
      filter_name: "",
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      filter_startdate: null,
      filter_enddate: null,
      perpage: [30, 50, 100, 250, 500, 1000],
      brand: [],
      data: [],
      orderBy: [
        { id: "idbrand", name: "Brand" },
        // { id: "boothkey", name: "Boothkey" },
        // { id: "price", name: "Price" },
        { id: "name", name: "Name" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      err: null,
      is_loading: false,
      page: 1,
      hasMore: true,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }

  handleChangeFilter(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  params = () => {
    let params = {};
    if (this.state.filter_idbrand !== 0 && this.state.filter_idbrand !== "0") {
      params.idbrand = this.state.filter_idbrand;
    }
    if (this.state.filter_name !== "") {
      params.name = this.state.filter_name;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }
    if (
      this.state.filter_startdate !== "" &&
      this.state.filter_startdate !== null
    ) {
      params.start_at = this.state.filter_startdate;
    }
    if (
      this.state.filter_enddate !== "" &&
      this.state.filter_enddate !== null
    ) {
      params.end_at = this.state.filter_enddate;
    }
    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;
    params.is_active = 1;

    return params;
  };

  handleFilterSubmit(e) {
    this.setState({ is_loading: true });
    e.preventDefault();
    const doc = document.getElementsByClassName("infinite-scroll-component");
    if (doc[0]) {
      doc[0].scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    }

    let params = this.params();

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_idbrand: this.state.filter_idbrand,
      filter_name: this.state.filter_name,
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
      filter_startdate: this.state.filter_startdate,
      filter_enddate: this.state.filter_enddate,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("frame_cookies", data, { path: "/", expires: expires });
    cookies.set("brand_filter", this.state.filter_idbrand, {
      path: "/",
      expires: expires,
    });

    this.props.getFrameAll(params).then((e) => {
      if (e.code === 200) {
        if (e.data !== null) {
          this.setState({
            data: e.data,
            is_loading: false,
            hasMore: e.data.length !== params.perpage ? false : true,
            page: 1,
          });
        } else {
          this.setState({
            data: [],
            is_loading: false,
            hasMore: false,
            page: 1,
          });
        }
      } else {
        this.setState({ data: [], is_loading: false });
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    var confirmed = window.confirm("Are you sure to delete this frame?");
    if (confirmed) {
      this.props
        .deleteFrame({ id: e.target.dataset.idframe })
        .then((e) => {
          if (e.code === 200) {
            // this.loadData();
            window.location.reload();
            alert("Delete Success");
          } else {
            alert("Something Error : " + e.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Something Error : " + e.message);
        });
    }
  }

  loadData() {
    var ctx = this;
    this.props.getBrandAll().then((e) => {
      if (e.code === 200) {
        this.setState({ brand: e.data });
      }
    });

    this.props
      .getFrameAll({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: this.state.page,
        is_active: 1,
      })
      .then(async (e) => {
        console.log("getFrameAll: ", e);
        if (e.code === 200) {
          if (e.data !== null) {
            ctx.setState({
              data: e.data,
              hasMore:
                e.data.length !== this.state.perpage[this.state.filter_perpage]
                  ? false
                  : true,
            });
          } else {
            ctx.setState({ data: [], hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message });
        }
        await this.fetchCookies();
      })
      .catch((e) => {
        alert("Something Error");
      });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  fetchCookies = () => {
    var brand_filter = this.props.cookies.get("brand_filter");
    if (brand_filter !== undefined) {
      this.setState({
        filter_idbrand: brand_filter,
      });
    }

    var frame_cookies = this.props.cookies.get("frame_cookies");
    if (frame_cookies !== undefined) {
      this.setState(
        {
          filter_idbrand: frame_cookies.filter_idbrand,
          filter_name: frame_cookies.filter_name,
          filter_perpage: frame_cookies.filter_perpage,
          filter_order: frame_cookies.filter_order,
          filter_sort: frame_cookies.filter_sort,
          filter_startdate: frame_cookies.filter_startdate,
          filter_enddate: frame_cookies.filter_enddate,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  loadFunc = () => {
    let params = this.params();
    params.page = this.state.page + 1;
    const ctx = this;
    // const nextpage = this.state.page + 1;
    this.props
      .getFrameAll(params)
      .then((e) => {
        if (e.code === 200) {
          ctx.setState({
            page: params.page,
          });
          if (e.data !== null) {
            setTimeout(() => {
              ctx.setState({
                data: this.state.data.concat(e.data),
                hasMore:
                  e.data.length !==
                  this.state.perpage[this.state.filter_perpage]
                    ? false
                    : true,
              });
            }, 1500);
          } else {
            this.setState({ hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_idbrand: 0,
        filter_name: "",
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
        filter_startdate: null,
        filter_enddate: null,
      },
      () => {
        this.props.cookies.remove("brand_filter", { path: "/", expires: 0 });
        this.props.cookies.remove("frame_cookies", { path: "/", expires: 0 });
        this.loadData();
      }
    );
  };

  handleChangeStartDate = (event, e) => {
    this.setState({
      filter_startdate: event,
    });
  };

  handleChangeEndDate = (event, e) => {
    this.setState({
      filter_enddate: event,
    });
  };

  render() {
    const url = this.props.match.url;
    var tableBody;

    if (this.state.data !== null) {
      tableBody = this.state.data.map((item, idx) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
            <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
              {(idx += 1)}
            </TableCell>
            <TableCell align="center">
              <img
                alt="full-cover-thumbnail"
                src={item.fullurl_image}
                width="50"
              />
            </TableCell>
            <TableCell align="left">
              {item.brands.length !== 0 ? (
                <Stack direction={"column"} spacing={2}>
                  {item.brands.map((el, index) => (
                    <Chip
                      key={index}
                      label={el.name}
                      className={"brand-color-" + el.id}
                      size="small"
                      sx={{ width: "fit-content" }}
                    />
                  ))}
                </Stack>
              ) : (
                <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                  Need a Brand!
                </Typography>
              )}
            </TableCell>
            <TableCell align="left">{item.name}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell
              align="center"
              sx={{ width: "1%", whiteSpace: "nowrap" }}
            >
              {item.is_active === true ? "Active" : "Not Active"}
            </TableCell>

            <TableCell>
              {item.start_at !== null
                ? format(
                    new Date(item.start_at.replace(/-/g, "/")),
                    "yyyy-MM-dd HH:mm"
                  )
                : null}
            </TableCell>
            <TableCell>
              {item.end_at !== null
                ? format(
                    new Date(item.end_at.replace(/-/g, "/")),
                    "yyyy-MM-dd HH:mm"
                  )
                : null}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <Grid
                container
                gap={1}
                alignItems="center"
                sx={{ justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    this.props.permissions.indexOf("edit frame") !== -1
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    this.props.history.push(url + "/edit/" + item.slug);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  data-idframe={item.id}
                  disabled={
                    this.props.permissions.indexOf("delete frame") !== -1
                      ? false
                      : true
                  }
                  onClick={this.handleDelete}
                >
                  Delete
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6} sx={{ textAlign: "right" }}>
            <Stack direction={"row"} spacing={2} justifyContent="flex-end">
              {this.props.permissions.indexOf("read frame") !== -1 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    this.props.history.push(url + "/archive");
                  }}
                >
                  Archive
                </Button>
              ) : null}
              {this.props.permissions.indexOf("create frame") !== -1 ? (
                <Button
                  alt="Test Experimental"
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: "10px" }}
                  onClick={(e) => {
                    this.props.history.push(url + "/addv3");
                  }}
                >
                  Add Frame
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-brand-label">Brand</InputLabel>
              <Select
                size="small"
                label="Brand"
                name="filter_idbrand"
                id="select-brand"
                labelId="select-brand-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_idbrand}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.brand.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              size="small"
              id="input-filtername"
              name="filter_name"
              label="Filter Name"
              placeholder="Signature"
              // sx={{height:"8px"}}
              onChange={this.handleChangeFilter}
              value={this.state.filter_name}
            ></TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="yyyy-MM-dd HH:mm"
                ampm={false}
                label="Date Start"
                name="filter_startdate"
                type="date"
                value={this.state.filter_startdate}
                onChange={this.handleChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "195px" }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="yyyy-MM-dd HH:mm"
                ampm={false}
                label="Date End"
                name="filter_enddate"
                type="date"
                value={this.state.filter_enddate}
                onChange={this.handleChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "195px" }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {/* <MenuItem value={0} key={-1}>25</MenuItem> */}
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "100px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "100px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
            {/* <Button variant="contained" onClick={this.handleFilterSubmit}>Filter</Button> */}
          </Grid>
          {/* <Grid item md={3} sx={{textAlign:"right"}}> */}
          {/* <Typography sx={{marginLeft:"5px"}}>Sort By</Typography> */}
          {/* </Grid> */}
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 570 }}>
            <InfiniteScroll
              // id="infinite-scroll-component"
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="center">Preview</TableCell>
                    <TableCell align="left">Brand</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="left">Start At</TableCell>
                    <TableCell align="left">End At</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody}</TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
          {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={10}
                        rowsPerPage={15}
                        page={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
        </Paper>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBrandAll: (data) => dispatch(getBrandAll(data)),
    getFrameAll: (data) => dispatch(getFrameAll(data)),
    deleteFrame: (data) => dispatch(deleteFrame(data)),
    getFrameSearch: (data) => dispatch(getFrameSearch(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(FrameAll))
);
